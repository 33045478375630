import BasePlugin from '../BasePlugin'

export default class CreateIndicatorsInProgramScenario extends BasePlugin {
  async execute () {
    let decisionId = this.context.getModel().id
    this.context.getCard().setLoading(true)
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CalculateFinancialIndicatorsForDecisionCommand`,
      { 'decision_id': decisionId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getDashboardComponents()['component_501fe4c6-266e-4f71-b2b6-59a6854eced7'][0].loadData()
      this.context.getCard().setLoading(false)
    })
  }
}
